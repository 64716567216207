import { defineStore } from 'pinia';
import axios from "axios";

let originalSetInterval = window.setInterval;
let intervalTimerList = [];


export const viewData = defineStore('viewData', {
    state: () => {
        return {
            vppTarget: null,
            mainMenuIndex: 0,
            isShowSubMenu: false,
            isMobile: false,
            isLogin: false,
            currentUser: {},
            currentUserIsAdmin: false,
            showDrawer: false,
            drawerName: '',
            drawerTitle: '',
            drawerWidth: 600,
            drawerParameter: {},
            drawerCallbackData: {},
            drawerCloseCallback: null,
            windowWidth: window.innerWidth,
            filterTypeList: [
                '全部類型', 'hr',
                '焦點案場', '問題案場', 'hr',
                '光電案場', '沼氣案場', '熱泵案場', '儲能案場', '其它案場', 'hr',
                '監控案場',
                '第三方案場'
            ],
            selectFilter: '全部類型',
            selectCity: '全部地區',
            selectTarget: '全部案場',
        }
    },
    getters: {
        isMobile() {
            // 設定視窗寬度閾值以判斷是否為移動裝置
            const mobileWidthThreshold = 768;
            return this.windowWidth <= mobileWidthThreshold;
        },
    },
    actions: {
        handleWindowResize() {
            this.windowWidth = window.innerWidth;
            try{
                this.drawerWidth = window.innerWidth * 0.8;
                if (window.innerWidth >= 969) this.drawerWidth = window.innerWidth * 0.6;
                if (window.innerWidth >= 1500) this.drawerWidth = window.innerWidth * 0.4;
            }catch(err){
                console.log(err);
            }
        },
        openDrawer(drawerName, drawerTitle, drawerParameter, afterCloseCallback){
            drawerTitle = drawerTitle || ''
            drawerParameter = drawerParameter || {}
            this.showDrawer = true
            this.drawerTitle = drawerTitle
            this.drawerParameter = drawerParameter
            setTimeout(()=>{
                this.drawerName = drawerName
            }, 330)
            this.drawerCloseCallback = null;
            if (typeof afterCloseCallback === 'function') this.drawerCloseCallback = afterCloseCallback
        },
        closeDrawer(){
            this.drawerName = 'undefined'
            console.log('closeDrawer', this.drawerCallbackData)
            if (typeof this.drawerCloseCallback === 'function') this.drawerCloseCallback(this.drawerCallbackData)
        },
        setPageInterval(callback, ms, keep = false){
            keep = keep || false
            let i = originalSetInterval(callback, ms);
            if (!keep) intervalTimerList.push(i);
        },
        clearPageInterval (){
            intervalTimerList.forEach(i=>{clearInterval(i);});
            intervalTimerList = [];
        },
        async canUserAccess (){
            // check if user is login
            if (this.isLogin) return true
            const {data} = await axios.get('/api/user/current')
            if (data.login) this.currentUser = data.user
            return data.login;
        },
        async beforePageChange(to,from){
            this.clearPageInterval()
            const canAccess = await this.canUserAccess()
            this.isLogin = canAccess
            console.log(from.path)
            console.log(to.path)
            if (to.path === '/user/Login' || to.path === '/'){
                if (!canAccess) {
                    return true
                } else {
                    if (location.pathname === '' || location.pathname === '/'){
                        return '/overview/Dashboard'
                    }else{
                        return location.pathname
                    }
                }
            }
            if (!canAccess) {
                return '/user/Login'
            }
        },
        afterPageChange(){
            let path = location.pathname
            let pageName = path.split('/').join('_').substring(1)
            if (pageName.length === 1) pageName = 'components_' + pageName
            this.pageAreaName = pageName
            setTimeout(()=>{
                this.mainMenuName = pageName.split("_")[0]
            }, 30)
        },
        getFormData(object) {
            let formData = new FormData();
            Object.keys(object).forEach(key => formData.append(key, object[key]));
            return formData;
        },
        dataFieldWithType(){
            let s = this.dataFieldList
            if (!s) return []
            if (this.selectFilter === '光電案場')
                s = this.dataFieldList.filter(f => f.kind.indexOf('PV')>=0)
            if (this.selectFilter === '沼氣案場')
                s = this.dataFieldList.filter(f => f.kind.indexOf('BG')>=0)
            if (this.selectFilter === '熱泵案場')
                s = this.dataFieldList.filter(f => f.kind.indexOf('HP')>=0)
            if (this.selectFilter === '儲能案場')
                s = this.dataFieldList.filter(f => f.kind.indexOf('ES')>=0)
            if (this.selectFilter === '其它案場')
                s = this.dataFieldList.filter(f => f.kind.indexOf('OT')>=0)
            if (this.selectFilter === '焦點案場')
                s = this.dataFieldList.filter(f => f.kind.indexOf('FOCUS')>=0)
            if (this.selectFilter === '問題案場')
                s = this.dataFieldList.filter(f => f.kind.indexOf('ERROR')>=0)
            if (this.selectFilter === '監控案場')
                s = this.dataFieldList.filter(f => f.kind.indexOf('MONITOR')>=0)
            if (this.selectFilter === '第三方案場')
                s = this.dataFieldList.filter(f => f.kind.indexOf('THIRD')>=0)
            return s.filter(c=>c.name.indexOf(this.searchKeyword) >= 0|| c.address.indexOf(this.searchKeyword) >= 0)
        },
        dataField(){
            let c = this.selectCity === "全部地區" ? "" : this.selectCity
            let f = [].concat(this.dataFieldWithType.filter(f=>f.city.indexOf(c) >= 0))
            f.sort((a, b) => a.name > b.name ? 1: -1)
            f.unshift({'name': '全部案場'})
            return f
        },
        loadFieldData(callback){
            axios.get(`/api/field/list.json?size=99999`).then((res2) => {
                let d = res2.data
                if (Array.isArray(d)) this.dataFieldList = d
                this.dataFieldList.forEach(x=>{
                    x.city = x.address.substr(0, 3)
                })
                this.selectFieldList = this.dataField.filter(x=>x.id)
                if (typeof callback === "function") callback()
            })
        },
    },
})

export default viewData