<template>
    <div class="layout" :class="{'mobile': view.isMobile}">
        <div id="nav" :style="{position: 'fixed', width: '100%'}">
            <img id="logo" src="/img/logo-2023.png" alt="logo" class="shrink-logo shrinked">
            <h1 style="margin-left: 80px; display: inline-block;">六和能源廠域</h1>
            <ul class="navMenu">

                <li><Icon type="md-contacts" /></li>
                <li>
                    <Notification icon="md-notifications">
                        <NotificationTab title="通知" name="message" :count="0">
                            <template #empty>
                            目前沒有通知
                            </template>
                            <NotificationItem v-for="(item, index) in messageList" :key="index" />
                        </NotificationTab>
                        <NotificationTab title="關注" name="follow" :count="0">
                            <template #empty>
                            尚未有關注的項目
                            </template>
                            <NotificationItem v-for="(item, index) in messageList" :key="index" />
                        </NotificationTab>
                    </Notification>
                </li>
                <li><Icon @click.prevent.stop="logout" type="md-log-out" /></li>
            </ul>
         </div>
        <div id="sidebar">
            <ul class="mainMenu">
                <template v-for="(m, index) in pageInfoData['mainMenu']" :key="m.title">
                <li @click="showSubMenu(index)">
                    <Icon :type="m.icon" />
                    <span>{{ m.title }}</span>
                </li>
                <ul class="subMenu">
                  <li :class="submenu.page" v-show="view.mainMenuIndex === index" v-for="submenu in pageInfoData[m.name]" :key="submenu">
                      <a :title="'/' + submenu.dir + '/' + submenu.page" @click.prevent.stop="goto(submenu)">{{ submenu.title }}</a>
                  </li>
                </ul>
                </template>
            </ul>
        </div>
        <div id="content">
            <router-view v-if="isRouterAlive"></router-view>
        </div>
        <Drawer :title="view.drawerTitle" :width="view.drawerWidth" :closable="false" v-model="view.showDrawer" @on-close="view.closeDrawer">
            <component v-bind:is="view.drawerName"
                       :drawerTitle="view.drawerTitle"
                       :drawerParameter="view.drawerParameter"
                       :mask-closable="false"
                       :drawerName="view.drawerName" :openDrawer="view.openDrawer">
            </component>
        </Drawer>

    </div>
</template>

<script>

const generateHexagon = (x, y, size) => {
    const points = [];
    for (let i = 0; i < 6; i++) {
        const angle = 2 * Math.PI / 6 * (i + 0.5);
        const pointX = x + size * Math.cos(angle);
        const pointY = y + size * Math.sin(angle);
        points.push(`${pointX},${pointY}`);
    }
    return points.join(" ");
};

const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

const createRandomHexagons = (count) => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const hexagons = [];
    const animationClasses = ["move-up", "move-down", "move-left", "move-right", "rotating", "rotating2", "scaling"];
    const colors = [
        "rgba(255,192,203,0.3)",
        "rgba(173,216,230,0.3)",
        "rgba(240,230,242,0.3)",
        "rgba(231,199,123,0.3)",
        "rgba(157,227,136,0.3)",
        "rgba(239,58,58,0.1)",
        "rgba(0,85,255,0.1)",
        "rgba(0,0,0,0.1)"
    ]; // 粉紅、粉藍、淡紫色

    for (let i = 0; i < count; i++) {
        const x = randomInt(0, width);
        const y = randomInt(0, height);
        const size = randomInt(10, 50);
        const points = generateHexagon(x, y, size);

        const animationClass = animationClasses[randomInt(0, animationClasses.length - 1)];
        const fillColor = colors[randomInt(0, colors.length - 1)];

        hexagons.push({ id: i, points, classes: animationClass, fill: fillColor  });
    }

    return hexagons;
};

export default {
    name: 'App',
    components: {},
    setup() {
    },
    computed: {
        path() {
            return this.$route.path
        }
    },
    data() {
        return {
            navBarVisible: false,
            hexagons: createRandomHexagons(25),
            isRouterAlive: true,
            messageList: [],
        }
    },
    unmounted() {
        window.removeEventListener('resize', this.view.handleWindowResize);
    },
    mounted() {
        window.addEventListener('resize', this.view.handleWindowResize);
        if (location.host === 'localhost:5001') {
            this.showNavBar()
            document.getElementById("logo").classList.add("shrink-logo");
            this.$router.push('/overview/Dashboard')
        }else{
            this.api.get('/api/user/current').then((res) => {
                let data = res.data
                this.view.currentUser = data.current_user
                let user_string = JSON.stringify(data.current_user)
                let isAnonymousUser = (user_string === '{}' || user_string === 'null')
                if (isAnonymousUser) {
                    this.$router.push('/user/Login')
                }
                this.$router.push('/user/Login')
            })
        }
    },
    methods: {
        goto(submenu){
            console.log('goto', submenu.page)
            this.$router.push(`/${submenu.dir}/${submenu.page}`)
        },
        showSubMenu(subIndex){
            this.view.mainMenuIndex = subIndex
        },
        logoClick(){
        },
        showNavBar() {
          document.getElementById("logo").classList.add("shrinked");
          this.navBarVisible = true;
        },
        logout(){
            let notice = this.$Notice
            notice.config({
                top: 70,
                duration: 1.8
            });
            notice.warning({'title': '正在登出…'})
            this.api.post('/api/user/logout').then(()=>{
                this.view.isLogin = false
                this.view.currentUser = {}
                this.$router.push('/user/Login')
            })
        },
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(() => {
                this.isRouterAlive = true
            });
            return false
        },
        getFormData(object) {
            let formData = new FormData();
            Object.keys(object).forEach(key => formData.append(key, object[key]));
            return formData;
        },
    },
}
</script>

<style lang="stylus" scoped>
#nav
    background: #8ac0e2;
    background: -moz-linear-gradient(left, #8ac0e2 0%, #4070bc 100%);
    background: -webkit-linear-gradient(left, #8ac0e2 0%,#4070bc 100%);
    background: linear-gradient(to right, #8ac0e2 0%,#4070bc 100%);
    border-bottom: 0px solid #ebeef5;
    h1
        margin: 15px 0 15px 90px;
        font-size: 20px;
        line-height: 36px;
        background: #fff;
        padding: 0 25px;
        border-radius: 8px;
        width: 200px;

.navMenu
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    width 200px;
    float right;

    li
        list-style: none;
        cursor: pointer;
        font-weight: 600;
        transition: all 0.3s ease;
        float: left;
        border: solid 5px transparent;
        line-height: 54px;
        text-align: center;
        font-size: 26px;
        color: #fff;
        width: 50px;

        &:hover
            color: #0b3465;


#content{

}
</style>
