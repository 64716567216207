export {pageInfoData};

let pageInfoData = {
    'mainMenu':[
        {'name': 'overview', title: '概要總覽', icon: 'ios-apps'},
        {'name': 'case', title: '案場狀態', icon: 'md-information'},
        {'name': 'trading', title: '電力交易', icon: 'logo-usd'},
        {'name': 'esg', title: 'ESG管理', icon: 'md-browsers'},
        {'name': 'dispatch', title: '維運派工', icon: 'md-build'},
        {'name': 'analyze', title: '數據分析', icon: 'md-analytics'},
        {'name': 'setting', title: '系統設定', icon: 'md-settings'},
    ],
    'overview':[
        {dir: 'overview', title: '基本資料', page: 'undefined'},
        {dir: 'overview', title: '儀錶板', page: 'Dashboard'},
    ],
    'case':[
        {dir: 'case', title: '基本資料', page: 'System'},
        {dir: 'case', title: '即時數據', page: 'chartPage'},
        {dir: 'case', title: '即時數據-儲能', page: 'chartESS'},
        {dir: 'case', title: '統計資料', page: 'undefined'},
        {dir: 'case', title: '事件報告', page: 'eventReport'},
        {dir: 'case', title: '報表匯出', page: 'reportExport'},
    ],
    'trading':[
        {dir: 'trading', title: '交易資源', page: 'Trade'},
        {dir: 'trading', title: '設備排程', page: 'undefined'},
        {dir: 'trading', title: '報價提交', page: 'undefined'},
        {dir: 'trading', title: '報價記錄', page: 'undefined'},
        {dir: 'trading', title: '未報價日期', page: 'undefined'},
        {dir: 'trading', title: '帳單結算', page: 'undefined'},
    ],
    'esg': [
        {dir: 'esg', title: '碳排放看板', page: 'co2Board'},
        {title: '能耗統計與分析', page: 'undefined'},
        {title: '碳排統計與分析', page: 'undefined'},
        {title: '報表匯出', page: 'undefined'},
    ],
    'dispatch':[
        {dir: 'dispatch', title: '派工排程', page: 'undefined'},
        {dir: 'dispatch', title: '設備妥善表', page: 'undefined'},
        {dir: 'dispatch', title: '設備定期保養', page: 'undefined'},
        {dir: 'dispatch', title: '設備異常表', page: 'undefined'},
        {dir: 'dispatch', title: '報表產出', page: 'undefined'},
    ],
    'analyze': [
        {dir: 'analyze', title: '發電分析', page: 'genElectric'},
        {dir: 'analyze', title: '發電預測', page: 'undefined'},
        {dir: 'analyze', title: '負載分析', page: 'genLoad'},
        {dir: 'analyze', title: '負載預測', page: 'undefined'},
        {dir: 'analyze', title: '能源調度策略', page: 'undefined'},
        {dir: 'analyze', title: '調度記錄頁面', page: 'Ai'},
    ],
    'setting':[
        {title: '用戶管理', page: 'undefined'},
        {title: '通知設定', page: 'undefined'},
        {title: '能源設備管理清單', page: 'deviceManage'},
    ],
    'my':[
        {dir: 'my', title: '我的虛擬電廠', page: 'vpp'},
    ],
    'system':[
        {dir:'system', title:'設備管理', page:'deviceManage'}
    ],
    // 非主選單
    'login':[
        {dir: 'user', title: '登入', page: 'Login'}
    ],
    'component':[
    ],
    'page':[
    ],
    // 獨立頁面、測試用頁面
    'instant':[
        {dir:'instant', title:'即時數據', page:'chartPage'},
        {dir:'instant', title:'即時數據', page:'essPage'}
    ],
    'vpp':[
        {dir: 'vpp', title: '電廠概要儀表板', page: 'Dashboard'},
        {dir: 'vpp', title: '3D 建物', page: 'Building3D'},
    ],
}

