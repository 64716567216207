import {createApp, defineAsyncComponent} from 'vue'
import axios from 'axios'
import {createRouter, createWebHistory} from 'vue-router'
import VueAxios from 'vue-axios'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import './css/app.css'
import App from './App.vue'
import './registerServiceWorker'
import moment from "moment"
import dataProxy from '@/plugins/dataProxy'
import {viewData} from '@/plugins/view'
import VXETable from 'vxe-table'
import {createPinia} from 'pinia'
import 'vxe-table/lib/style.css'
import {pageInfoData} from '@/operating.js'
import {Chart, registerables} from 'chart.js';
import 'chartjs-adapter-moment';
import './registerServiceWorker'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'

Chart.register(...registerables);
moment.locale('zh-tw');

const pinia = createPinia()
const app = createApp(App)

app.use(pinia)

const vs = viewData()

let routes = []
function loadComponent(page, title, dir){
    if (typeof page === "undefined") return false
    dir = dir || 'page'
    let component = defineAsyncComponent(() =>
        import(`@/page/${dir}/${page}.vue`)
    )
    routes.push({
        path: `/${dir}/${page}`, component: component
    })
    app.component(dir + '_' + page, component)
}

Object.keys(pageInfoData).forEach(key=>{
    let d = pageInfoData[key];
    d.forEach(i=>{
        if (!i.dir) i.dir = key
        loadComponent(i.page, i.title, i.dir)
        if (i.items && i.items.length){
            i.items.forEach(j=>{
                if (!j.dir) j.dir = key
                loadComponent(j.page, j.title, j.dir)
            })
        }
    });
});



const router = createRouter({
    history: createWebHistory(),
    routes,
})
router.beforeEach(async (to, from) => {
    return vs.beforePageChange(to, from)
})
router.afterEach((to, from, failure) => {
    if (!failure) vs.afterPageChange()
})
// router.beforeEach(async (to, from) => {
//     return vs.beforePageChange(to, from)
// })
// router.afterEach((to, from, failure) => {
//     if (!failure) vs.afterPageChange()
// })

library.add(fas)

app
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(VueAxios, axios)
    .use(ViewUIPlus)
    .use(router)
    .use(dataProxy)
    .use(VXETable)

app.config.globalProperties.Chart = Chart
app.config.globalProperties.$moment = moment
app.config.globalProperties.pageInfoData = pageInfoData
app.config.globalProperties.view = vs
app.config.globalProperties.viewStore = vs

app.mount('#app')